var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "suspend" }, [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("headbox", {
          attrs: {
            title: "浮悬窗报价",
            bgColor: "#39394d",
            isbackPath: true,
            path: _vm.path
          }
        }),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "quotedPrice flex_between" }, [
            _c("div", { staticClass: "text" }, [_vm._v("浮悬窗报价")]),
            _c(
              "div",
              { staticClass: "open" },
              [
                _c("van-switch", {
                  attrs: {
                    value: _vm.suspend,
                    "active-color": "#39394D",
                    "inactive-color": "#ffffff"
                  },
                  on: { input: _vm.onInput }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "category flex_between" }, [
            _c("div", { staticClass: "text" }, [_vm._v("悬浮窗显示品种")]),
            _c(
              "div",
              {
                staticClass: "catgoryText flex_between",
                on: { click: _vm.changeCatgory }
              },
              [
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.priceText == "huangjin9999" ? "黄金" : "伦敦金")
                  )
                ]),
                _c("van-icon", { attrs: { name: "arrow", color: "#999999" } })
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }